import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Label } from 'components';
import { spaceBase } from 'design-system/variables/spaces';
import { SelectedDateRange } from '../../interfaces';

const PreferredDatesList = styled.ul`
	display: flex;
	flex-wrap: wrap;
	margin-top: ${spaceBase};
`;

const PreferredDatesListItem = styled.li`
	display: inline;
	margin-right: ${spaceBase};
`;

export const PreferredDates: React.FC<{ preferredDates: SelectedDateRange[] }> = ({ preferredDates }) => {
	const renderPreferredDates = () => {
		return preferredDates.map(dateRange => {
			const formattedDateRange = `${moment(dateRange.startDate).format('M/D/YYYY')} - ${moment(
				dateRange.endDate,
			).format('M/D/YYYY')}`;

			return <PreferredDatesListItem key={formattedDateRange}>{formattedDateRange}</PreferredDatesListItem>;
		});
	};

	return (
		<React.Fragment>
			<Label size='medium'>Preferred Dates</Label>
			<PreferredDatesList>{renderPreferredDates()}</PreferredDatesList>
		</React.Fragment>
	);
};
