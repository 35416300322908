import React, { FC, useEffect, useState } from 'react';
import { Paragraph, Badge } from 'components';
import axios from 'axios';
import { Member, UnitWatchList, Configuration } from './interfaces';
import { getMemberWatchLists, deleteWatchList } from './watchlistService';
import {
	WatchListWrap,
	WatchListPageHeader,
	WatchListPageTitle,
	WatchListTableLabel,
	WatchListLoader,
	WatchListNotification,
} from './WatchListPageStyles';
import { WatchListCardTable } from './components/index';
import { MemberContext } from './Context/MemberContext';
import { ConfigurationContext } from './Context/index';

interface WatchListPageProps {
	configuration: Configuration;
	member: Member;
}

const WatchListPage: FC<WatchListPageProps> = ({ configuration, member }) => {
	const [watchList, setWatchList] = useState<UnitWatchList[]>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const [notificationType, setNotificationType] = useState('default');

	useEffect(() => {
		setIsLoading(true);

		getMemberWatchLists(member.accountUserId)
			.then(response => setWatchList(response))
			.finally(() => setIsLoading(false));
	}, [member]);

	axios.defaults.baseURL = configuration.baseApiUrl;

	const displayNotification = (message, type) => {
		setNotificationType(type);
		setNotificationMessage(message);
	};

	const removeWatchList = async (unitWatchList: UnitWatchList) => {
		setIsLoading(true);

		await deleteWatchList(unitWatchList.watchListId)
			.then(() => {
				watchList.splice(watchList.indexOf(unitWatchList), 1);
				setWatchList([...watchList]);
				displayNotification(
					`${unitWatchList.accommodationName} successfully removed from Watch List.`,
					'success',
				);
			})
			.catch(error => displayNotification('Oh snap. Something has gone terribly wrong.', 'error'))
			.finally(() => setIsLoading(false));
	};

	return (
		<ConfigurationContext.Provider value={configuration}>
			<MemberContext.Provider value={member}>
				<WatchListWrap>
					<WatchListPageHeader>
						<WatchListPageTitle>
							<span>Watch List</span>
						</WatchListPageTitle>
						<Paragraph>
							Cult of Skaro you are not alone in the universe Allons-y Delete. Delete. Delete. Galifrey
							Galifreyan Spoilers! Stormageddon, Dark Lord of All Amy Pond ninehundred YOU WILL OBEY! I
							hereby invoke The Shadow Proclamation! Spoilers! I hereby invoke The Shadow Proclamation! I
							am a Dalek Donna Noble The Supreme Dalek Puny Human! I really hate stairs. EXTERMINATE ALL
							STAIRCASES! EXTERMINATE! Galifrey Rory the Roman Bring the Humans to me Allons-y, Alonso!
							EXTERMINATE! Bad Wolf YOU WILL OBEY! Are you my mummy?
						</Paragraph>
					</WatchListPageHeader>
					<WatchListTableLabel size="medium">
						{member.firstName}'s Watch List: <Badge>{watchList ? watchList.length : 0}</Badge>
						{isLoading && <WatchListLoader />}
					</WatchListTableLabel>
					{notificationMessage && (
						<WatchListNotification
							type={notificationType}
							onClose={() => setNotificationMessage('')}
							isOpen={!!notificationMessage}
						>
							{notificationMessage}
						</WatchListNotification>
					)}
					{watchList && (
						<WatchListCardTable
							watchList={watchList}
							onWatchListRemove={removeWatchList}
						></WatchListCardTable>
					)}
				</WatchListWrap>
			</MemberContext.Provider>
		</ConfigurationContext.Provider>
	);
};

export default WatchListPage;
