import React from 'react';
import styled from 'styled-components';
import { spaceBase } from 'design-system/variables/spaces';
import { TextButton } from 'components/TextButton';

const ActionsContainer = styled.div`
	display: flex;
	height: 40px;
	justify-content: space-between;
	padding-left: ${spaceBase};
	padding-right: ${spaceBase};
	user-select: none;
`;

interface ActionsBarProps {
	applyEnabled: boolean;
	clearEnabled: boolean;
	onClearClick: () => void;
	onApplyClick: () => void;
}

export const ActionsBar: React.FC<ActionsBarProps> = ({
	applyEnabled,
	clearEnabled,
	onClearClick,
	onApplyClick,
	...rest
}) => {
	return (
		<ActionsContainer {...rest}>
			<TextButton onClick={onClearClick} isDisabled={!clearEnabled}>
				Clear Dates
			</TextButton>
			<TextButton onClick={onApplyClick} isDisabled={!applyEnabled}>
				Apply
			</TextButton>
		</ActionsContainer>
	);
};
