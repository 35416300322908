import styled from 'styled-components';
import { GridWrap, Title, Label, LoaderFlat, Notification } from 'components';
import { spaceLarge, spaceBig, spaceBase } from 'design-system/variables/spaces';
import { white, dividerGray, textColorDark } from 'design-system/variables/colors';
import { sm } from 'design-system/media-queries';

export const WatchListWrap = styled(props => <GridWrap {...props} />)`
	background-color: ${white};
	height: 100%;
	padding-top: ${spaceBig};
`;

export const WatchListPageHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: ${spaceLarge};
	margin-left: auto;
	margin-right: auto;
	max-width: 700px;

	p {
		display: none;

		${sm`
			display: block;
		`}
	}
`;

export const WatchListPageTitle = styled(props => <Title {...props} />)`
	&::before {
		border-bottom: ${dividerGray};
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		width: 100%;
	}

	position: relative;
	text-align: center;
	width: 100%;

	span {
		background-color: ${white};
		padding-left: ${spaceBase};
		padding-right: ${spaceBase};
		position: relative;
		z-index: 2;
	}
`;

export const WatchListTableLabel = styled(props => <Label {...props} />)`
	${textColorDark}

	display: block;
	margin-bottom: ${spaceLarge};
	position: relative;
`;

export const WatchListLoader = styled(props => <LoaderFlat {...props} />)`
	position: absolute;
	top: 0;
`;

export const WatchListNotification = styled(props => <Notification {...props} />)`
	transform: translateY(-${spaceBase});
`;
