import React from 'react';
import { Icon, Calendar } from 'components';
import { white } from 'design-system/variables/colors';
import { SelectedDateRange } from '../../interfaces';
import { CalendarContainer, CalendarHeader, CloseCalendarButton } from './WatchListCalendarStyles';
import { ActionsBar } from '../ActionsBar/ActionsBar';

interface WatchListCalendarProps {
	onDateRangeSelected: (dateRange) => void;
	showCalendar: boolean;
	onClose: () => void;
}

export const WatchListCalendar: React.FC<WatchListCalendarProps> = ({ onDateRangeSelected, showCalendar, onClose }) => {
	const calendarRef = React.useRef(null);
	const [selectedDateRange, setSelectedDateRange] = React.useState<SelectedDateRange>(null);
	const [applyEnabled, setApplyEnabled] = React.useState(false);
	const [clearEnabled, setClearEnabled] = React.useState(false);

	React.useEffect(() => {
		setApplyEnabled(!!(selectedDateRange && selectedDateRange.startDate && selectedDateRange.endDate));
		setClearEnabled(!!(selectedDateRange && selectedDateRange.startDate));
	}, [selectedDateRange]);

	const handleClose = () => {
		calendarRef.current.props.reset();
		onClose();
	};

	const handleApplyClick = () => {
		if (applyEnabled) {
			onDateRangeSelected(selectedDateRange);
			handleClose();
		}
	};

	const handleSelectedDays = dateRange => {
		setSelectedDateRange(dateRange);
	};

	const handleClearClick = () => {
		calendarRef.current.props.click();
	};

	return (
		<CalendarContainer showCalendar={showCalendar}>
			<CalendarHeader>
				<Icon iconName='info' iconSize='md' fill={white} />
				&nbsp;
				<span>Calendar does not show current availability.</span>
				<CloseCalendarButton iconName='close' iconSize='md' onClick={handleClose} buttonType='primary' />
			</CalendarHeader>
			<Calendar ref={calendarRef} onDaysSelected={handleSelectedDays} />
			<ActionsBar
				applyEnabled={applyEnabled}
				clearEnabled={clearEnabled}
				onApplyClick={handleApplyClick}
				onClearClick={handleClearClick}
			/>
		</CalendarContainer>
	);
};
