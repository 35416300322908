import React, { FC, useState, useContext } from 'react';
import { Subtext, Link } from 'components';
import { spaceBase } from 'design-system/variables/spaces';
import { MemberContext, ConfigurationContext } from '../../Context';
import { UnitWatchList } from '../../interfaces';
import { PreferredDates } from '../PreferredDates/PreferredDates';
import {
	ListCardWrapper,
	ImageContainer,
	Image,
	CardDetails,
	TitleContainer,
	CardTitle,
	SpecsContainer,
	Spec,
	StatsLabel,
	CardCommandPanel,
	Popup,
	PopupCommandPanel,
} from './WatchListCardStyles';
import { WatchListSlideOutLink } from '../WatchListSlideOut/WatchListSlideout';

interface WatchListCardProps {
	unit: UnitWatchList;
	onRemove: (watchList) => void;
}

export const WatchListCard: FC<WatchListCardProps> = ({ unit, onRemove }) => {
	const url = unit.overrideUrl || unit.unitUrl;
	const [displayPopup, setDisplayPopup] = useState(false);
	const member = useContext(MemberContext);
	const configuration = useContext(ConfigurationContext);

	const handleRemove = () => {
		onRemove(unit);
		setDisplayPopup(false);
	};

	return (
		<ListCardWrapper>
			<ImageContainer href={url}>
				<Image src={`${configuration.imageServer}${unit.coverImage}&width=300`} />
			</ImageContainer>

			<CardDetails>
				<TitleContainer>
					<CardTitle title={unit.accommodationName}>{unit.accommodationName}</CardTitle>
					<Subtext title={unit.destinationName}>{unit.destinationName}</Subtext>
				</TitleContainer>
				<SpecsContainer>
					<Spec>
						{unit.bedrooms}
						<StatsLabel>br</StatsLabel>
					</Spec>
					<Spec>
						{unit.bathrooms}
						<StatsLabel>ba</StatsLabel>
					</Spec>
					<Spec>
						{unit.occupancy}
						<StatsLabel>occ</StatsLabel>
					</Spec>
					<Spec>
						{unit.squareFeet}
						<StatsLabel>sqft</StatsLabel>
					</Spec>
				</SpecsContainer>
				<PreferredDates preferredDates={unit.selectedDates}></PreferredDates>
				<CardCommandPanel>
					<WatchListSlideOutLink unitId={unit.unitId} member={member}>
						Edit
					</WatchListSlideOutLink>
					<Link color='primary' onClick={() => setDisplayPopup(true)} style={{ marginLeft: spaceBase }}>
						Remove
					</Link>
					<Popup theme='light' arrowPosition='topRight' isOpen={displayPopup}>
						<p>Are you sure you want to remove from Watch List?</p>
						<PopupCommandPanel>
							<Link color='primary' onClick={() => setDisplayPopup(false)}>
								Cancel
							</Link>
							<Link color='primary' onClick={handleRemove}>
								Remove
							</Link>
						</PopupCommandPanel>
					</Popup>
				</CardCommandPanel>
			</CardDetails>
		</ListCardWrapper>
	);
};
