import axios from 'axios';
import { UnitWatchList, SelectedDateRange } from './interfaces';

axios.defaults.headers.common['Authorization'] =
	'Bearer MDlhNWFlNWI5LWM4OWUtNDJlZS1iODk3LTJiZTM4ZmU3NjBmNXw2MzU5NTQ3NDU1OTI3MzMzNjN8Mnwy%7c6C9NbRFrzmd53MLx%2bRGQXZZ5k6cxwRuj0PNTyHB9BDw%3d';

export const getUnitWatchList = async (unitId: number, accountUserId: number): Promise<UnitWatchList> => {
	return axios
		.get(`/watch-list/`, {
			params: {
				unitId,
				accountUserId,
			},
		})
		.then(response => response.data);
};

export const getMemberWatchLists = async (accountUserId: number): Promise<UnitWatchList[]> => {
	return axios
		.get('/watch-list/', {
			params: {
				accountUserId,
			},
		})
		.then(response => response.data);
};

export const deleteWatchList = async (watchListId: number): Promise<void> => {
	await axios.delete('/watch-list/', {
		params: {
			watchListId,
		},
	});
};

export const addDateRange = async (watchListId: number, startDate: Date, endDate: Date): Promise<SelectedDateRange> => {
	const dateRange = { startDate, endDate };

	return axios.post(`/watch-list/${watchListId}/`, dateRange).then(response => response.data);
};

export const deleteDateRange = async (listingDatesId: number): Promise<void> => {
	await axios.delete(`/watch-list/listing-dates/`, {
		data: {
			listingDatesId,
		},
	});
};
