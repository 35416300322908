import styled, { createGlobalStyle } from 'styled-components';
import { Overlay, Title, Label, Button, IconButton } from 'components';
import { boxShadow3 } from 'design-system/style-mixins/shadows-and-scrims';
import { white } from 'design-system/variables/colors';
import { spaceLarge, spaceBase, spaceSmall } from 'design-system/variables/spaces';
import { sm } from 'design-system/media-queries';
import { bold, textSmall } from 'design-system/style-mixins/text';
import { transition250ms } from 'design-system/style-mixins/animations';

export const DisableScroll = createGlobalStyle`
	html {
		overflow-y: hidden;
	}
 `;

export const WatchListOverlay = styled(props => <Overlay {...props} />)`
	${transition250ms}

	background: ${props => (props.isOpen ? 'default' : 'none')};
	flex-direction: column;
	pointer-events: ${props => (props.isOpen ? 'auto' : 'none')};
	position: fixed;
	z-index: 9001;
`;

export const SlideOut = styled.div<{ isOpen: boolean }>`
	${boxShadow3}
	${transition250ms}

	background-color: ${white};
	min-height: 100vh;
	padding-top: ${spaceLarge};
	position: relative;
	left: ${props => (props.isOpen ? '0' : '100vw')};
	width: 100%;

	${sm`
		left: ${props => (props.isOpen ? 'calc(100vw - 570px)' : '100vw')};
		width: 570px;
	`}
`;

export const SlideOutHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${spaceLarge};
`;

export const SlideOutTitle = styled(props => <Title {...props} />)`
	${bold}
`;

export const Instructions = styled.p`
	${textSmall}

    margin-bottom: ${spaceLarge};
`;

export const SelectedDatesContainer = styled.div`
	margin-bottom: ${spaceLarge};
	margin-top: ${spaceLarge};
	width: 100%;
`;

export const SelectedDatesLabel = styled(props => <Label {...props} />)`
	display: block;
	margin-bottom: ${spaceBase};
`;

export const DateRangeList = styled.ul`
	margin-top: ${spaceBase};
	max-height: 25vh;
	overflow-y: auto;

	${sm`
		max-height: 40vh;
	`}
`;

export const DateRangeListItem = styled.li`
	display: flex;
	justify-content: space-between;
	margin-bottom: ${spaceSmall};
	padding: 0;
	width: 100%;
`;

export const FullWidthButton = styled(props => <Button {...props} />)`
	margin-bottom: ${spaceBase};
	max-width: 100%;
	width: 100%;
`;

export const RemoveDateRangeButton = styled(props => <IconButton {...props} />)`
	height: 24px;
	width: 24px;
`;

export const CommandPanelButton = styled(props => <Button {...props} />)`
	max-width: 49%;
	width: 49%;
`;
