import styled from 'styled-components';
import { white, black } from 'design-system/variables/colors';
import { spaceSmall, spaceMedium } from 'design-system/variables/spaces';
import { textSmall, textBase } from 'design-system/style-mixins/text';
import { boxShadow3 } from 'design-system/style-mixins/shadows-and-scrims';
import { sm } from 'design-system/media-queries';
import { IconButton } from 'components/Icon';

export const CalendarContainer = styled.div<{ showCalendar: boolean }>`
	${boxShadow3}

	background-color: ${white};
	display: ${props => (props.showCalendar ? 'block' : 'none')};
	height: 100vh;
	left: 0;
	position: absolute;
	top: 0;
	z-index: 9002;

	${sm`
		height: auto;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	`}
`;

export const CalendarHeader = styled.div`
	${textSmall}

	background-color: ${black};
	color: ${white};
	display: flex;
	justify-content: center;
	padding: ${spaceSmall};

	${sm`
		${textBase}
	`}
`;

export const CloseCalendarButton = styled(props => <IconButton {...props} />)`
	height: ${spaceMedium};
	position: absolute;
	right: ${spaceSmall};
	top: ${spaceSmall};
	width: ${spaceMedium};
`;
