import React, { FC, useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Link, GridWrap, LoaderFlat, DetailCard } from 'components';
import { WatchListNotification } from '../../WatchListPageStyles';
import { MemberContext, ConfigurationContext } from '../../Context';
import { UnitWatchList, SelectedDateRange, Member, Configuration } from '../../interfaces';
import { getUnitWatchList, addDateRange, deleteDateRange } from '../../watchlistService';

import {
	DateRangeListItem,
	RemoveDateRangeButton,
	WatchListOverlay,
	SlideOut,
	SlideOutHeader,
	SlideOutTitle,
	Instructions,
	SelectedDatesContainer,
	SelectedDatesLabel,
	FullWidthButton,
	DateRangeList,
	DisableScroll,
} from './WatchListSlideOutStyles';
import { WatchListCalendar } from '../WatchListCalendar/WatchListCalendar';

interface WatchListSlideOutProps {
	unitId: number;
	showSlideOut: boolean;
	onClose: () => void;
}

const WatchListSlideOut: FC<WatchListSlideOutProps> = ({ unitId, showSlideOut, onClose }) => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [watchList, setWatchList] = React.useState<UnitWatchList>(null);
	const [selectedDates, setSelectedDates] = React.useState<SelectedDateRange[]>([]);
	const [showCalendar, setShowCalendar] = React.useState(false);
	const [notificationMessage, setNotificationMessage] = useState('');
	const member = useContext(MemberContext);

	useEffect(() => {
		if (showSlideOut && unitId > 0) {
			setIsLoading(true);
			getUnitWatchList(unitId, member.accountUserId)
				.then(results => {
					setWatchList(results);
				})
				.finally(() => setIsLoading(false));
		}
	}, [showSlideOut]);

	useEffect(() => {
		if (watchList) {
			setSelectedDates(watchList.selectedDates);
		}
	}, [watchList]);

	const clear = () => {
		setWatchList(null);
		setSelectedDates([]);
	};

	useEffect(() => {
		if (!showSlideOut) {
			clear();
		}
	}, [showSlideOut]);

	const sortSelectedDateRanges = (l: SelectedDateRange, r: SelectedDateRange) => {
		const startDate = moment(l.startDate);
		const endDate = moment(l.endDate);

		if (startDate.isBefore(r.startDate) || (startDate.isSame(r.startDate) && endDate.isBefore(r.endDate))) {
			return -1;
		}

		if (startDate.isAfter(r.startDate) || (startDate.isSame(r.startDate) && endDate.isAfter(r.endDate))) {
			return 1;
		}

		return 0;
	};

	const handleDateRangeSelected = (dateRange: SelectedDateRange) => {
		const selectedDateRanges = [...selectedDates];
		const isMatchingDateRange = (l: SelectedDateRange, r: SelectedDateRange) => {
			return moment(l.startDate).isSame(r.startDate, 'date') && moment(l.endDate).isSame(r.endDate, 'date');
		};

		if (!selectedDateRanges.find(x => isMatchingDateRange(x, dateRange))) {
			addDateRange(watchList.watchListId, dateRange.startDate, dateRange.endDate)
				.then(response => {
					selectedDateRanges.push(response);
					const sortedSelectedDateRanges = [...selectedDateRanges].sort(sortSelectedDateRanges);
					setSelectedDates(sortedSelectedDateRanges);
				})
				.catch(response => setNotificationMessage(response.message))
				.finally(() => setShowCalendar(false));
		}
	};

	const removeDateRange = (dateRange: SelectedDateRange) => {
		deleteDateRange(dateRange.id)
			.then(() => setSelectedDates(selectedDates.filter(x => x !== dateRange)))
			.catch(response => setNotificationMessage(response.message));
	};

	const renderWatchListDates = () => {
		const format = (dateRange: SelectedDateRange) => {
			return `${moment(dateRange.startDate).format('M/D/YYYY')} - ${moment(dateRange.endDate).format(
				'M/D/YYYY',
			)}`;
		};

		return selectedDates.map(x => (
			<DateRangeListItem key={format(x)}>
				{format(x)}
				<RemoveDateRangeButton
					iconName='close'
					iconSize='sm'
					buttonType='containerless'
					onClick={() => removeDateRange(x)}
				/>
			</DateRangeListItem>
		));
	};

	return (
		<WatchListOverlay contrast='light' isOpen={showSlideOut}>
			<WatchListCalendar
				showCalendar={showCalendar}
				onDateRangeSelected={handleDateRangeSelected}
				onClose={() => setShowCalendar(false)}
			/>
			<SlideOut isOpen={showSlideOut}>
				<GridWrap>
					<SlideOutHeader>
						<SlideOutTitle size='small'>Watch List</SlideOutTitle>
						<Link color='primary' onClick={onClose}>
							Cancel
						</Link>
					</SlideOutHeader>
					{notificationMessage && (
						<WatchListNotification
							type='error'
							onClose={() => setNotificationMessage('')}
							isOpen={!!notificationMessage}
						>
							{notificationMessage}
						</WatchListNotification>
					)}
					<Instructions>
						Save accommodations to your Watch List to receive availability and pricing notifications. You
						can view or edit your Watch List items in your account.
					</Instructions>
					<DetailCard {...watchList} />
					<SelectedDatesContainer>
						<SelectedDatesLabel size='medium'>Preferred Dates</SelectedDatesLabel>
						<DateRangeList>{renderWatchListDates()}</DateRangeList>
					</SelectedDatesContainer>
					<FullWidthButton buttonType='secondary' onClick={() => setShowCalendar(true)}>
						Add Dates
					</FullWidthButton>
					<FullWidthButton buttonType='primary' onClick={onClose}>
						Done
					</FullWidthButton>
					{isLoading && <LoaderFlat />}
				</GridWrap>
			</SlideOut>
		</WatchListOverlay>
	);
};

interface WatchListSlideOutLinkProps {
	configuration?: Configuration;
	member: Member;
	unitId: number;
}

export const WatchListSlideOutLink: FC<WatchListSlideOutLinkProps> = ({ configuration, member, unitId, children }) => {
	const [showSlideOut, setShowSlideOut] = useState(false);

	const openSlideOut = () => {
		setShowSlideOut(true);
	};

	return (
		<ConfigurationContext.Provider value={configuration}>
			<MemberContext.Provider value={member}>
				{showSlideOut && <DisableScroll />}
				<Link color='primary' onClick={openSlideOut}>
					{children || 'Save to Watch List'}
				</Link>
				<WatchListSlideOut unitId={unitId} showSlideOut={showSlideOut} onClose={() => setShowSlideOut(false)} />
			</MemberContext.Provider>
		</ConfigurationContext.Provider>
	);
};
