import React from 'react';
import { UnitWatchList } from '../../interfaces';
import { WatchListCard } from '../WatchListCard/WatchListCard';

export const WatchListCardTable: React.FC<{
	watchList: UnitWatchList[];
	onWatchListRemove: (watchList) => void;
}> = ({ watchList, onWatchListRemove }) => {
	const renderCards = () => {
		return watchList.map(unit => (
			<WatchListCard key={`Unit_${unit.unitId}`} {...{ unit, onRemove: onWatchListRemove }} />
		));
	};

	return <React.Fragment>{renderCards()}</React.Fragment>;
};
