import styled from 'styled-components';
import { fontSans, textBase, bold, textTiny, textSmall } from 'design-system/style-mixins/text';
import { spaceLarge, spaceBase, spaceMedium, spaceTiny } from 'design-system/variables/spaces';
import { brokenImgHoriz } from 'design-system/utils';
import { textColorDark, textColorLight, dividerGray } from 'design-system/variables/colors';
import { transparentDark10 } from 'design-system/style-mixins/shadows-and-scrims';
import { shadowHover } from 'design-system/style-mixins/animations';
import { md, sm } from 'design-system/media-queries';
import { Tooltip } from 'components/Tooltip';

export const ListCardWrapper = styled.div`
	${fontSans}
	${shadowHover}
	${textBase}

	border-bottom: ${dividerGray};
	display: flex;
	flex-direction: column;
	flex-flow: row wrap;
	margin-bottom: ${spaceLarge};
	padding: 0;
	padding-bottom: ${spaceLarge};
	text-align: left;
	user-select: none;

	${md`
		border-top: none;
		box-shadow: none;
		flex-direction: row;

		&:hover {
		border-top: none;
		box-shadow: none;
		}
	`}
`;

export const ImageContainer = styled.a`
	display: flex;
	flex-basis: 100%;
	flex-direction: column;

	${md`
		flex-basis: 33%;
		height: 100%;
		width: 100%;
	`}
`;

export const Image = styled.div<{ src?: string }>`
	background-color: ${transparentDark10};
	background-image: url(${props => props.src}), url(${brokenImgHoriz});
	background-position: center;
	background-size: cover;
	padding-bottom: 56.5%;
	position: relative;
`;

export const CardDetails = styled.div`
	${textBase}

	display: flex;
	flex-basis: 66%;
	flex-direction: column;
	flex-grow: 1;
	padding-left: ${spaceBase};
	padding-top: ${spaceBase};
	position: relative;

	${sm`
		padding-left: ${spaceMedium};
		padding-top: 0;
	`}
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${spaceBase};
	max-width: 250px;
	overflow: hidden;
`;

export const CardTitle = styled.div`
	${textColorDark}
	${bold}

	margin-bottom: ${spaceBase};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const SpecsContainer = styled.div`
	${textTiny}

	display: flex;
	margin-bottom: ${spaceBase};
	width: 100%;
`;

export const Spec = styled.div`
	${textColorLight}

	display: flex;
	margin-right: ${spaceBase};
`;

export const StatsLabel = styled.span`
	display: inline;
	margin-left: ${spaceTiny};
`;

export const CardCommandPanel = styled.div`
	padding-right: ${spaceBase};
	position: absolute;
	right: 0;
`;

export const Popup = styled(props => <Tooltip {...props} />)`
	${textSmall}

	padding: ${spaceBase};
	padding-top: 0;
	position: absolute;
	right: 18px;
	width: 200px;
`;

export const PopupCommandPanel = styled.div`
	display: flex;
	justify-content: space-between;
`;
